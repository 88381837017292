import React, { useState } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { v4 } from "uuid"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import {
  Section,
  SectionTitle,
  SectionPageTitle,
  SectionLead,
  BreadCrumb,
} from "../components/Section"
import CallDesignBuilding from "../components/CallDesignBuilding"
import GetInTouch from "../components/GetInTouch"
import BreakpointUp from "../components/Media/BreakpointUp"
import {
  ListColorBorad,
  ColorItem,
  ColorCode,
  ColorName,
} from "../components/ColorBoard"

const SectionGrid = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: column-reverse;
  ${BreakpointUp.lg`     
        flex-direction: inherit;
    `}
  ${BreakpointUp.xl`        
        margin-top: -280px;      
    `}
`
const SectionGridLeft = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`        
        flex: 0 0 41.666667%;
        max-width: 41.666667%;
    `}
`
const Thumbnail = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
const ThumbFigure = styled.figure`
  position: relative;
  width: 100%;
  max-height: 400px;
  margin-bottom: 0;
  &:before {
    content: attr(data-shadowtext);
    position: absolute;
    bottom: 100%;
    color: rgba(0, 0, 0, 0.1);
    font-weight: 900;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    text-align: center;
    font-size: 28px;
    line-height: 32px;
    @media (min-width: 768px) {
      font-size: 38px;
      line-height: 32px;
    }
    @media (min-width: 992px) {
      font-size: 48px;
      line-height: 42px;
    }
  }
  & .gatsby-image-wrapper {
    height: 100%;
    max-height: 400px;
  }
`
const SectionGridRight = styled.div`
  position: relative;
  width: 100%;
  ${BreakpointUp.lg`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
`
const TopHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  justify-content: space-between;
`
const TopHeaderLeft = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 33.333333%;
        max-width: 33.333333%;
    `}
`
const TopHeaderRight = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  ${BreakpointUp.lg`
        flex: 0 0 58.333333%;
        max-width: 58.333333%;
    `}
`
const CardColor = styled.div`
  padding: 30px 10px;
  ${BreakpointUp.md`
    padding: 40px 25px;
  `}
  ${BreakpointUp.xl`
    padding:85px 65px;
  `}
  background-color:#fff;
  box-shadow: 0 15px 45px rgba(0, 0, 0, 0.2);
  h2 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 900;
    margin-bottom: 15px;
  }
`

const ColorOption = ({ data, location }) => {
  const [currentColor, setColor] = useState("Pewter Gray")
  const allColors = data.allContentfulBuildingColor
  return (
    <Layout location={location}>
      <Seo title="Metal Building Color Options | Steel Building Color Schemes" description="Metal Building Color Options - We offer metal buildings and carports with 12 color options. Click here to see them all, and call us now to start designing yours today!" />
      <BreadCrumb>
        <div className="container">
          <Link to="/">Home / </Link>
          <span>Color Option</span>
        </div>
      </BreadCrumb>
      <Section
        pt="215px"
        pb="300px"
        xpt="120px"
        xpb="30px"
        bgColor="#F2F4F9"
        className="circle-left sm"
      >
        <div className="container">
          <TopHeader>
            <TopHeaderLeft>
              <SectionPageTitle textAlign="left" maxWidth="1120px" ml="0">
                Metal Building
              </SectionPageTitle>
            </TopHeaderLeft>
            <TopHeaderRight>
              <SectionLead textAlign="left" maxWidth="1000px" ml="0">
                We currently offer 17 Different Colors in both 29 gauge and 26
                gauge Panels and colors small Colors & gauges are available for
                both trim and panels. We now also offer an uncolored Galvalume
                panel!
              </SectionLead>
            </TopHeaderRight>
          </TopHeader>
        </div>
      </Section>
      <Section pt="0" pb="90px" xpt="60px" xpb="60px" bgColor="transparent">
        <div className="container">
          <SectionGrid>
            <SectionGridLeft>
              <CardColor>
                <h2>Available Color Options</h2>
                <ListColorBorad>
                  {allColors.edges.map(item => (
                    <ColorItem
                      key={v4()}
                      onClick={() => setColor(item.node.name)}
                    >
                      <ColorCode bg={item.node.colorCode}></ColorCode>
                      <ColorName>{item.node.name}</ColorName>
                    </ColorItem>
                  ))}
                </ListColorBorad>
              </CardColor>
            </SectionGridLeft>
            <SectionGridRight>
              <Thumbnail className="thumbnail">
                <ThumbFigure
                  className="thumb-img"
                  data-shadowtext={currentColor}
                >
                  {allColors.edges.map(item => {
                    if (currentColor === item.node.name) {
                      return (
                        <GatsbyImage
                          key={v4()}
                          image={item.node.sampleImage.gatsbyImageData}
                          alt={item.node.sampleImage.title}
                        />
                      )
                    }
                    return true
                  })}
                </ThumbFigure>
              </Thumbnail>
            </SectionGridRight>
          </SectionGrid>
        </div>
      </Section>
      <Section
        pt="90px"
        pb="90px"
        xpt="60px"
        xpb="60px"
        bgColor="#F2F4F9"
        className="circle-right md"
      >
        <div className="container mw-1180">
          <SectionTitle maxWidth="725px" mb="50px">
            {data.contentfulColorSamplesPage.formSectionTitle}
          </SectionTitle>
          <GetInTouch states={data.allContentfulState.edges} location={location} />
        </div>
      </Section>
      <CallDesignBuilding />
    </Layout>
  )
}

export default ColorOption

export const pageQuery = graphql`
  query ColorOptionQuery {
    contentfulColorSamplesPage {
      metaTitle
      metaDescription {
        metaDescription
      }
      pageName
      heroTitle
      heroDescription {
        childMarkdownRemark {
          html
        }
      }
      formSectionTitle
    }
    allContentfulBuildingColor {
      edges {
        node {
          name
          colorCode
          sampleImage {
            title
            gatsbyImageData
          }
        }
      }
    }
    allContentfulState(sort: { order: ASC, fields: name }) {
      edges {
        node {
          name
        }
      }
    }
  }
`
